import { RouterOutputs } from 'helpers/trpc';

export const playersPerPage = 10;
export const defaultPage = 1;
export const aiScoutClubId = 92;
export const minColumnWidth = 120;
export const delayApiSave = 2500;
export const retryGaps = [5, 15, 30, 60];
export const defaultRetiedCount = 0;
export const secondToRetry = 0;
export const defaultSessionId = '';
export const defaultStringValue = '';
export const failedToFetchErrorMessage = 'Failed to fetch';
export const failedToSaveErrors = {
  failedToFetch: 'Not Connected',
  unKnownError: 'Something went wrong While Saving',
};

type MaturationDataType = {
  height: number | null;
  weight: number | null;
  sitHeight: number | null;
  actualSitHeight: number | null;
  collectionDate: Date | null;
};

export type MaturationData = MaturationDataType[] | null;

export type MaturationPlayer = {
  id: string;
  name: string | null;
  sessionNumber: number;
  profileImage: string;
  dateOfBirth: Date;
  maturationData: MaturationData;
  previousMaturationData: MaturationDataType | null;
};

export type MaturationDataEntry = {
  playerId: string;
  sessionId: string;
  weight?: number | null;
  height?: number | null;
  sitHeight?: number | null;
  actualSitHeight?: number | null;
  collectionDate?: Date | null;
};

export type TrialListEplSessions =
  RouterOutputs['performanceLab']['getTrialListEplSessions'][number];
