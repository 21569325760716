import { Box, SxProps, Theme, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

interface Props {
  containerTitle?: ReactNode;
  convertTitle?: boolean;
  readonly children?: ReactNode;
  readonly containerRight?: ReactNode;
  readonly sx?: SxProps<Theme>;
  containerTitleStyle?: SxProps<Theme>;
}

const customStyles = {
  mainContainer: {
    flex: 1,
    p: 6,
    backgroundColor: '#F6F6F6',
    borderRadius: 6,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 4,
    overflow: 'hidden',
    gap: 1,
  },
  containerTitle: {
    maxWidth: '80%',
    mb: 0,
  },
};

const ContentContainer = ({
  containerTitle,
  containerRight,
  convertTitle = true,
  children,
  sx,
  containerTitleStyle,
}: Props) => (
  <Box
    sx={
      sx ? { ...customStyles.mainContainer, ...sx } : customStyles.mainContainer
    }
  >
    {(containerTitle || containerRight) && (
      <Box sx={customStyles.headerContainer}>
        <Typography
          variant="h2"
          sx={{
            textTransform: convertTitle ? 'capitalize' : 'none',
            ...containerTitleStyle,
          }}
        >
          {containerTitle}
        </Typography>

        {containerRight}
      </Box>
    )}

    {children}
  </Box>
);

export default ContentContainer;
