import { Avatar, Stack } from '@mui/material';

type AvatarVariant = 'circular' | 'rounded' | 'square';

interface Props {
  imageSource: string;
  variant?: AvatarVariant;
  isPerformanceLab?: boolean;
}

const CustomStepAvatar = ({
  imageSource,
  variant,
  isPerformanceLab = false,
}: Props) => (
  <Stack direction="row" sx={customStyles.detailStack}>
    <Stack direction="row">
      <Avatar
        src={imageSource}
        sx={customStyles.avatar(isPerformanceLab)}
        variant={variant}
      />
    </Stack>
  </Stack>
);

export default CustomStepAvatar;

const customStyles = {
  avatar: (isPerformanceLab: boolean) => ({
    position: 'relative',
    right: 8,
    top: isPerformanceLab ? 3 : 4,
    background: 'transparent',
    zIndex: 1,
  }),
  detailStack: {
    justifyContent: 'space-between',
    alignItems: 'center',
    mt: -2,
  },
};
