import {
  aiscout_gender_player,
  aiscout_preferred_foot,
} from 'db/generated/app';

export const playerPositions = [
  'All',
  'Attacking Midfield',
  'Centre Midfield',
  'Centre Back',
  'Centre Forward',
  'Defensive Midfield',
  'Goalkeeper',
  'Left Back',
  'Left Midfield',
  'Left Wing',
  'Left Wing Back',
  'Right Back',
  'Right Midfield',
  'Right Wing',
  'Right Wing Back',
  'Striker',
  'Sweeper',
  'Wing Back',
  'Referee',
];

export const preferredFootSelections = [
  'ALL',
  ...Object.values(aiscout_preferred_foot).filter((item) => item !== 'NONE'),
] as const;
export const gender = ['male', 'female'] as const;
export const trialTypes = ['physical', 'cognitive'] as const;
export const genderOptions = [...Object.values(aiscout_gender_player)] as const;
export const genderSelections = [
  'ALL',
  ...genderOptions.filter((item) => item !== 'UNSPECIFIED'),
] as const;

export const AllPositionGroupOption = {
  name: 'All Position Groups',
  id: '0',
  positionIds: [
    '2d116616-3bf4-43ad-8b55-0e71a8b549fe',
    '3508ae64-ab9f-4bfc-a8fe-211afd61925c',
    '443bd530-595c-47fe-8aa5-999c537abecd',
    '5c67c975-0eb7-4793-a0a8-2e02ebda3849',
    '5ef93fa9-263d-435c-9f91-114ceb652e8a',
    '73f26664-5481-4a87-81d4-01e9696d8366',
    '779d4794-2a57-428f-a53c-21abb56faef9',
    '7a979451-2e26-455c-8fb1-27d565368dde',
    'b2597d97-a4e6-4e93-910e-54cf232f9118',
    'b8e61fd3-4a10-4415-98fe-87e17cbde31d',
    'ba496d0f-1561-4832-a857-4465317d2c75',
    'ce1c9b42-9db7-4023-8057-8f850dd7be8a',
    'd688c24f-6d39-4b82-ae1c-6338d5fcdbd0',
    'd934b4c2-e979-41dc-81eb-853a88e70e79',
    'e352af06-13c9-4a44-a257-b792959edb35',
    'f0342ce5-d753-4510-87fd-eb9cca48c727',
    'f3a8b551-67b1-4501-8552-177e49c57d73',
    'fabfceee-4a7f-48b0-a355-b3cf94760ef0',
  ],
};

export const AllPositionOption = {
  id: 0,
  name: 'All Positions',
};

export const defaultStringValue = '';
